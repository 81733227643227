
import type { NuxtSvgSpriteSymbol } from './runtime'
type SymbolImport = {
  import: () => Promise<string>
  attributes: Record<string, string>
}
export const SYMBOL_IMPORTS: Record<NuxtSvgSpriteSymbol, SymbolImport> = {
'add-circled-outline': { import: () => import('/builds/bazinga/publica/frontend/assets/symbols/add-circled-outline.svg?raw').then(v => v.default), attributes: {"viewBox":"0 0 24 24","fill":"none","xmlns":"http://www.w3.org/2000/svg","id":"add-circled-outline"} },
  'arrow-down': { import: () => import('/builds/bazinga/publica/frontend/assets/symbols/arrow-down.svg?raw').then(v => v.default), attributes: {"viewBox":"0 0 24 24","fill":"none","xmlns":"http://www.w3.org/2000/svg","id":"arrow-down"} },
  'arrow-left': { import: () => import('/builds/bazinga/publica/frontend/assets/symbols/arrow-left.svg?raw').then(v => v.default), attributes: {"viewBox":"0 0 24 24","fill":"none","xmlns":"http://www.w3.org/2000/svg","id":"arrow-left"} },
  'arrow-right': { import: () => import('/builds/bazinga/publica/frontend/assets/symbols/arrow-right.svg?raw').then(v => v.default), attributes: {"viewBox":"0 0 24 24","fill":"none","xmlns":"http://www.w3.org/2000/svg","id":"arrow-right"} },
  'arrow-up': { import: () => import('/builds/bazinga/publica/frontend/assets/symbols/arrow-up.svg?raw').then(v => v.default), attributes: {"viewBox":"0 0 24 24","fill":"none","xmlns":"http://www.w3.org/2000/svg","id":"arrow-up"} },
  'attachment': { import: () => import('/builds/bazinga/publica/frontend/assets/symbols/attachment.svg?raw').then(v => v.default), attributes: {"viewBox":"0 0 24 24","fill":"none","xmlns":"http://www.w3.org/2000/svg","id":"attachment"} },
  'building': { import: () => import('/builds/bazinga/publica/frontend/assets/symbols/building.svg?raw').then(v => v.default), attributes: {"viewBox":"0 0 24 24","fill":"none","xmlns":"http://www.w3.org/2000/svg","id":"building"} },
  'bulb': { import: () => import('/builds/bazinga/publica/frontend/assets/symbols/bulb.svg?raw').then(v => v.default), attributes: {"viewBox":"0 0 24 24","fill":"none","xmlns":"http://www.w3.org/2000/svg","id":"bulb"} },
  'cancel': { import: () => import('/builds/bazinga/publica/frontend/assets/symbols/cancel.svg?raw').then(v => v.default), attributes: {"viewBox":"0 0 24 24","fill":"none","xmlns":"http://www.w3.org/2000/svg","id":"cancel"} },
  'chat-bubble-question': { import: () => import('/builds/bazinga/publica/frontend/assets/symbols/chat-bubble-question.svg?raw').then(v => v.default), attributes: {"viewBox":"0 0 24 24","fill":"none","xmlns":"http://www.w3.org/2000/svg","id":"chat-bubble-question"} },
  'check-circled-filled': { import: () => import('/builds/bazinga/publica/frontend/assets/symbols/check-circled-filled.svg?raw').then(v => v.default), attributes: {"viewBox":"0 0 24 24","fill":"none","xmlns":"http://www.w3.org/2000/svg","id":"check-circled-filled"} },
  'check-circled-outline': { import: () => import('/builds/bazinga/publica/frontend/assets/symbols/check-circled-outline.svg?raw').then(v => v.default), attributes: {"viewBox":"0 0 24 24","fill":"none","xmlns":"http://www.w3.org/2000/svg","id":"check-circled-outline"} },
  'check': { import: () => import('/builds/bazinga/publica/frontend/assets/symbols/check.svg?raw').then(v => v.default), attributes: {"viewBox":"0 0 24 24","fill":"none","xmlns":"http://www.w3.org/2000/svg","id":"check"} },
  'circled-outline': { import: () => import('/builds/bazinga/publica/frontend/assets/symbols/circled-outline.svg?raw').then(v => v.default), attributes: {"viewBox":"0 0 24 24","fill":"none","xmlns":"http://www.w3.org/2000/svg","id":"circled-outline"} },
  'close': { import: () => import('/builds/bazinga/publica/frontend/assets/symbols/close.svg?raw').then(v => v.default), attributes: {"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 96 960 960","id":"close"} },
  'delete-circled-outline': { import: () => import('/builds/bazinga/publica/frontend/assets/symbols/delete-circled-outline.svg?raw').then(v => v.default), attributes: {"viewBox":"0 0 24 24","fill":"none","xmlns":"http://www.w3.org/2000/svg","id":"delete-circled-outline"} },
  'headset': { import: () => import('/builds/bazinga/publica/frontend/assets/symbols/headset.svg?raw').then(v => v.default), attributes: {"fill":"none","xmlns":"http://www.w3.org/2000/svg","color":"#000","id":"headset"} },
  'home': { import: () => import('/builds/bazinga/publica/frontend/assets/symbols/home.svg?raw').then(v => v.default), attributes: {"viewBox":"0 0 24 24","fill":"none","xmlns":"http://www.w3.org/2000/svg","id":"home"} },
  'image': { import: () => import('/builds/bazinga/publica/frontend/assets/symbols/image.svg?raw').then(v => v.default), attributes: {"viewBox":"0 0 24 24","fill":"none","xmlns":"http://www.w3.org/2000/svg","id":"image"} },
  'info-empty': { import: () => import('/builds/bazinga/publica/frontend/assets/symbols/info-empty.svg?raw').then(v => v.default), attributes: {"viewBox":"0 0 24 24","fill":"none","xmlns":"http://www.w3.org/2000/svg","id":"info-empty"} },
  'lock': { import: () => import('/builds/bazinga/publica/frontend/assets/symbols/lock.svg?raw').then(v => v.default), attributes: {"viewBox":"0 0 24 24","fill":"none","xmlns":"http://www.w3.org/2000/svg","id":"lock"} },
  'logo-small': { import: () => import('/builds/bazinga/publica/frontend/assets/symbols/logo-small.svg?raw').then(v => v.default), attributes: {"viewBox":"0 0 31 43","fill":"none","xmlns":"http://www.w3.org/2000/svg","id":"logo-small"} },
  'logo': { import: () => import('/builds/bazinga/publica/frontend/assets/symbols/logo.svg?raw').then(v => v.default), attributes: {"viewBox":"0 0 165 43","fill":"none","xmlns":"http://www.w3.org/2000/svg","id":"logo"} },
  'mail': { import: () => import('/builds/bazinga/publica/frontend/assets/symbols/mail.svg?raw').then(v => v.default), attributes: {"viewBox":"0 0 24 24","fill":"none","xmlns":"http://www.w3.org/2000/svg","id":"mail"} },
  'meeting': { import: () => import('/builds/bazinga/publica/frontend/assets/symbols/meeting.svg?raw').then(v => v.default), attributes: {"version":"1.1","id":"meeting","xmlns":"http://www.w3.org/2000/svg","xmlns:xlink":"http://www.w3.org/1999/xlink","x":"0px","y":"0px","viewBox":"0 0 1147.9 1206.4","style":"enable-background:new 0 0 1147.9 1206.4;","xml:space":"preserve"} },
  'megaphone': { import: () => import('/builds/bazinga/publica/frontend/assets/symbols/megaphone.svg?raw').then(v => v.default), attributes: {"viewBox":"0 0 24 24","fill":"none","xmlns":"http://www.w3.org/2000/svg","id":"megaphone"} },
  'menu': { import: () => import('/builds/bazinga/publica/frontend/assets/symbols/menu.svg?raw').then(v => v.default), attributes: {"viewBox":"0 0 17 14","fill":"none","xmlns":"http://www.w3.org/2000/svg","id":"menu"} },
  'nav-arrow-down': { import: () => import('/builds/bazinga/publica/frontend/assets/symbols/nav-arrow-down.svg?raw').then(v => v.default), attributes: {"viewBox":"0 0 24 24","fill":"none","xmlns":"http://www.w3.org/2000/svg","id":"nav-arrow-down"} },
  'nav-arrow-left': { import: () => import('/builds/bazinga/publica/frontend/assets/symbols/nav-arrow-left.svg?raw').then(v => v.default), attributes: {"viewBox":"0 0 24 24","fill":"none","xmlns":"http://www.w3.org/2000/svg","id":"nav-arrow-left"} },
  'nav-arrow-right': { import: () => import('/builds/bazinga/publica/frontend/assets/symbols/nav-arrow-right.svg?raw').then(v => v.default), attributes: {"viewBox":"0 0 24 24","fill":"none","xmlns":"http://www.w3.org/2000/svg","id":"nav-arrow-right"} },
  'nav-arrow-up': { import: () => import('/builds/bazinga/publica/frontend/assets/symbols/nav-arrow-up.svg?raw').then(v => v.default), attributes: {"viewBox":"0 0 24 24","fill":"none","xmlns":"http://www.w3.org/2000/svg","id":"nav-arrow-up"} },
  'open-in-window': { import: () => import('/builds/bazinga/publica/frontend/assets/symbols/open-in-window.svg?raw').then(v => v.default), attributes: {"viewBox":"0 0 24 24","fill":"none","xmlns":"http://www.w3.org/2000/svg","id":"open-in-window"} },
  'page': { import: () => import('/builds/bazinga/publica/frontend/assets/symbols/page.svg?raw').then(v => v.default), attributes: {"viewBox":"0 0 24 24","fill":"none","xmlns":"http://www.w3.org/2000/svg","id":"page"} },
  'phone': { import: () => import('/builds/bazinga/publica/frontend/assets/symbols/phone.svg?raw').then(v => v.default), attributes: {"viewBox":"0 0 24 24","fill":"none","xmlns":"http://www.w3.org/2000/svg","id":"phone"} },
  'play': { import: () => import('/builds/bazinga/publica/frontend/assets/symbols/play.svg?raw').then(v => v.default), attributes: {"viewBox":"44.9 44.9 83.2 83.2","fill":"none","xmlns":"http://www.w3.org/2000/svg","style":"filter: drop-shadow(0px 0px 3px rgb(0 0 0 / 0.4));","id":"play"} },
  'plus': { import: () => import('/builds/bazinga/publica/frontend/assets/symbols/plus.svg?raw').then(v => v.default), attributes: {"viewBox":"0 0 24 24","fill":"none","xmlns":"http://www.w3.org/2000/svg","id":"plus"} },
  'remove-empty': { import: () => import('/builds/bazinga/publica/frontend/assets/symbols/remove-empty.svg?raw').then(v => v.default), attributes: {"viewBox":"0 0 24 24","fill":"none","xmlns":"http://www.w3.org/2000/svg","id":"remove-empty"} },
  'search': { import: () => import('/builds/bazinga/publica/frontend/assets/symbols/search.svg?raw').then(v => v.default), attributes: {"viewBox":"0 0 24 24","fill":"none","xmlns":"http://www.w3.org/2000/svg","id":"search"} },
  'user': { import: () => import('/builds/bazinga/publica/frontend/assets/symbols/user.svg?raw').then(v => v.default), attributes: {"viewBox":"0 0 24 24","fill":"none","xmlns":"http://www.w3.org/2000/svg","id":"user"} },
  'warning-circled-outline': { import: () => import('/builds/bazinga/publica/frontend/assets/symbols/warning-circled-outline.svg?raw').then(v => v.default), attributes: {"viewBox":"0 0 24 24","fill":"none","xmlns":"http://www.w3.org/2000/svg","id":"warning-circled-outline"} },
  'warning-triangle-outline': { import: () => import('/builds/bazinga/publica/frontend/assets/symbols/warning-triangle-outline.svg?raw').then(v => v.default), attributes: {"viewBox":"0 0 24 24","fill":"none","xmlns":"http://www.w3.org/2000/svg","id":"warning-triangle-outline"} },
}
  