
/**
 * Keys of all generated SVG sprite symbols.
 */
export type NuxtSvgSpriteSymbol =
  | 'add-circled-outline'
  | 'arrow-down'
  | 'arrow-left'
  | 'arrow-right'
  | 'arrow-up'
  | 'attachment'
  | 'building'
  | 'bulb'
  | 'cancel'
  | 'chat-bubble-question'
  | 'check-circled-filled'
  | 'check-circled-outline'
  | 'check'
  | 'circled-outline'
  | 'close'
  | 'delete-circled-outline'
  | 'headset'
  | 'home'
  | 'image'
  | 'info-empty'
  | 'lock'
  | 'logo-small'
  | 'logo'
  | 'mail'
  | 'meeting'
  | 'megaphone'
  | 'menu'
  | 'nav-arrow-down'
  | 'nav-arrow-left'
  | 'nav-arrow-right'
  | 'nav-arrow-up'
  | 'open-in-window'
  | 'page'
  | 'phone'
  | 'play'
  | 'plus'
  | 'remove-empty'
  | 'search'
  | 'user'
  | 'warning-circled-outline'
  | 'warning-triangle-outline'

export const SPRITE_PATHS = {"default":"/_nuxt/sprite-default.fnJo8CS0Uj.svg"}

export type RuntimeOptions = {
  ariaHidden: boolean
}

export const runtimeOptions: RuntimeOptions = {"ariaHidden":false}
